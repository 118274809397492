// extracted by mini-css-extract-plugin
export var calculator = "xt_bQ4";
export var calculator_areas = "xt_bY4";
export var calculator_container = "xt_bW4";
export var calculator_cta = "xt_bT4";
export var calculator_inner = "xt_bR4";
export var calculator_saving = "xt_b04";
export var calculator_sliders = "xt_bZ4";
export var calculator_submit = "xt_bX4";
export var calculator_title = "xt_bS4";
export var calculator_titleForm = "xt_bV4";