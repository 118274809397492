// extracted by mini-css-extract-plugin
export var hero = "xr_k4";
export var hero_appstore = "xr_bH4";
export var hero_btn = "xr_bB4";
export var hero_buttons = "xr_bz4";
export var hero_content = "xr_p4";
export var hero_inner = "xr_n4";
export var hero_link = "xr_bC4";
export var hero_pic = "xr_s4";
export var hero_pic_item = "xr_bJ4";
export var hero_pic_item__laptop = "xr_bL4";
export var hero_pic_item__mobile = "xr_bK4";
export var hero_video = "xr_bD4";
export var hero_video_prev = "xr_bF4";
export var hero_video_title = "xr_bG4";
export var video = "xr_v4";
export var videoWrapper = "xr_t4";